/**
 * A RadioList renders a list of options as radio buttons under a specified category.
 * Supported props: category items onClick theme
 */
import React, {useState} from 'react';
import { removeFields } from '../util/pagetools';

export default function RadioList(props) {
  const rootProps = removeFields(props, ['category', 'items', 'onClick', 'theme']);
  const [choice, setChoice] = useState(null);

  function handleRadioPick(e) {
    setChoice(e.target.value);
    if (props.onClick) props.onClick(e);
  }

  return (
    <div className="radio-list-items-container" {...rootProps}>
      {props.items.map((item, index) => (
        <div key={`label_${index}`}>
          <label htmlFor={`${props.category}-${index}`} className="radio-label">
            <input key={`rb_${index}`}
                 type="radio"
                 id={`${props.category}-${index}`}
                 name={props.category || 'rbCategory'}
                 className={props.theme || 'rb-input'}
                 value={item.id}
                 checked={choice===item.id}
                 onChange={handleRadioPick} />
            {item.value}
          </label>
        </div>
      ))}
    </div>
  );
};
