/**
 * A Slide is a container for a Screener question or a conclusion page.
 */
import React, { useEffect } from 'react';
import Safe from './Safe';
import { adjustSlideHeight, css } from '../util/pagetools';

export default function Slide(props) {
  useEffect(() => {
    if (props.isActive) adjustSlideHeight();
  });

  if (props.hasOwnProperty('question')) return renderQuestion(props);
  return renderPage(props);
}

/**
 * Render a screener question.
 * @param object
 * @return object
 */
function renderQuestion(props) {
  const qs = props.question;
  const yesHandler = props.responder(qs, true);
  const noHandler = props.responder(qs, false);
  const slideStyle = css(getStyleSlideIn(props.isActive, props.isPast), qs.theme || 'bg-default-slide');
  const btnStyle = css('std-btn block-full-w noborder rounded-10', props.btnTheme);

  return (
    <div className={slideStyle}>
      <div className="slide-counter">{qs.label}</div>
      <div className='slide-text-container'>
        <Safe className="slide-h1-mb slide-question-text" content={qs.q} />
        <Safe className="slide-text" content={qs.text} />
      </div>
      <div className="row">
        <div className="col-lg-3 col-sm-4 col-xs-6"><button onClick={yesHandler} className={btnStyle}>{props.btnLabels.affirm}</button></div>
        <div className="col-lg-3 col-sm-4 col-xs-6"><button onClick={noHandler} className={btnStyle}>{props.btnLabels.deny}</button></div>
      </div>
    </div>
  );
}

/**
 * Render a conclusion page.
 * @param boolean - the active status
 * @param object - child elements
 * @return object
 */
function renderPage(props) {
  const slideStyle = css(getStyleSlideIn(props.isActive, props.isPast), props.theme || 'bg-default-slide');
  return <div className={slideStyle}>{props.children}</div>;
}

/**
 * Define styling using a slide-in transition.
 * @param boolean - is this slide the active slide
 * @param boolean - has this slide already been visited
 * @return string
 */
function getStyleSlideIn(isActive, isPast) {
  const xPos = (isActive ? 'slide-active' : (isPast ? 'slide-left' : 'slide-right'));
  return 'slide '+xPos;
}
