/**
 * Render a form from a configuration file that enumerates its fields.
 * Supported props: config onSubmit btnlabel
 */
import React, { useState, useRef } from 'react';
import formtool from '../util/formManager';
import { css } from '../util/pagetools';

export default function ScreenerForm(props) {
  const [formdata, setFormdata] = useState({});
  const [addressData, setAddressData] = useState({});
  const ft = formtool(props.config.formfields, formdata, setFormdata, addressData, setAddressData, props.isQualified);
  function onSubmit(e) {
    e.preventDefault();
    const formOk = ft.validateForm();
    if (!formOk) return;
    else if (!props.onSubmit) return;
    const payload = Object.assign({}, formdata, addressData);
    props.onSubmit(payload);
  }

  return (
    <form className="patient-form form-root mb-20 p-8">
      {ft.render()}
      <div className="flx-v-end form-group-fullw mt-12">
        <div className="btn-container">
          <button className={css('form-btn rounded-10 noborder', props.config.button.theme)} onClick={onSubmit}>{props.btnlabel}</button>
        </div>
      </div>
    </form>
  );
};
